import React from 'react';
import {NavLink} from 'react-router-dom';

// images
import homepagebanner from '../images/homepage.jpeg'
import allPage from '../images/allPage.png'
import allPlatfrom from '../images/allPlatform.png'
import monitor from '../images/monitor.png'
import insight from '../images/insight.png'
import insightBenchmark from '../images/insightBenchmark.png'
// partners
import sai from '../images/partners/sai.png'
import ppba from '../images/partners/ppba.png'
import haryanasteelers from '../images/partners/HaryanaSteelers.png'
import ffessm from '../images/partners/ffessm.png'
import jsw from '../images/partners/jsw.png'
import indiaAcceletor from '../images/partners/indiaAcceletor.png'
import madeit from '../images/partners/madeit.png'

// icons
import performance from '../images/icons/performance.png'
import collaboration from '../images/icons/collaboration.png'
import intelligence from '../images/icons/intelligence.png'
import securedData from '../images/icons/securedData.png'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// Component
import Payments from './Payments';
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
// pdf
import AtiumReport from '../pdf/ATIUM_REPORT.pdf'
import Testimonial from './Testimonials';

function getNewStyle(i) {
  return {
    width: window.innerWidth > 800 ? "calc(50% - 62px)" : "calc(100% - 30px)",
    marginLeft: i && window.innerWidth > 800 ? 0 : 30,
  }
}

const Para1 = styled(Paper)(({theme}) => ({
  backgroundColor: '#fafafd',
  border: '#181818',
  padding: '8px 30px',
  color: '#4F4F4F',
  fontFamily: 'raleway, sans-serif',
  fontWeight: 'normal',
  fontSize: 22,
  fontStyle: 'normal',
  textAlign: 'justify',
  letterSpacing: '0em',
  lineHeight: '1.6',
  marginTop: 30,
}));
const Para2 = styled(Paper)(({theme}) => ({
  backgroundColor: '#eceefd',
  border: '#181818',
  padding: theme.spacing(1),
  textAlign: 'left',
  color: '#4F4F4F',
  display: 'flex',
  fontWeight: 'normal',
  fontSize: '18px',
  fontStyle: 'normal',
  lineHeight: '1.6',
  margin: 30,
  marginBottom: 0,
}));
const trusted = {
  sai: sai,
  ppba: ppba,
  haryanasteelers: haryanasteelers,
  ffessm: ffessm
}
const partners = {
  jsw: jsw,
  indiaAcceletor: indiaAcceletor,
  madeit: madeit
}

export default function Home() {
  return (
    <div style={{width: '100%'}}>
      {/*  scroll 0 */}
      <div style={{position: 'relative'}}>
        <img src={homepagebanner} alt='homeBanner' className='image' />
        <div className="overlay">
          <h2 className='title fw600'><mark className="bgWhite">ATIUM SPORTS</mark></h2>
          <h2 className='subtitle fw600'><mark className="bgWhite">PERFORMANCE INTELLIGENCE PLATFORM</mark></h2>
          <h2 className='para'><mark className="bgWhite">Empowering Sports organisations and their athletes to reach their peak performance through digitization, Sports Science & Artificial Intelligence.</mark></h2>
          <div style={{display: 'flex', justifyContent: 'flex-end', margin: 30}}>
            <NavLink to="/contact" className='learn fw700 bgPurple' style={{
              color: '#616af6', fontSize: 30, padding: '10px 20px', borderRadius: '10px'
            }}>Try Now</NavLink>
          </div>
        </div>
      </div>
      {/* scroll 1 */}
      <Para1>
        <p>
          We go beyond traditional athlete management, integrating evidence-backed research to measure relevant training data. This not only streamlines collaboration but also facilitates faster and more informed decision-making.  ATIUM is a collaborative platform that brings together all stakeholders—athletes, coaches, medical staff, and leaders— onto a single data layer.
        </p>
      </Para1>
      <div className='dfrow'>
        <Para2 style={getNewStyle()}>
          <div className='ma'>
            <img src={performance} alt='Holistic' />
          </div>
          <div className='m15 fw600'>
            <p > Holistic Athlete Management</p>
            <p className='fw400'>ATIUM empowers you to manage athletes comprehensively, from tracking and monitoring training programs to gaining intelligent insights. </p>
          </div>
        </Para2>
        <Para2 style={getNewStyle(true)}>
          <div className='ma'>
            <img src={intelligence} alt='intelligence' />
          </div>
          <div className='m15 fw600'>
            <p> Performance Intelligence</p>
            <p className='fw400'>We leverage data against evidence-backed research to provide actionable insights, enabling you to enhance high-performance standards and cultivate elite athletes. </p>
          </div>
        </Para2>
        <Para2 style={getNewStyle()}>
          <div className='ma'>
            <img src={securedData} alt='securedData' />
          </div>
          <div className='m15 fw600'>
            <p> Single Data Layer</p>
            <p className='fw400'>All stakeholders can collaborate seamlessly on a unified platform, ensuring efficient communication and a cohesive approach to athlete development.</p>
          </div>
        </Para2>
        <Para2 style={getNewStyle(true)}>
          <div className='ma'>
            <img src={collaboration} alt='collaboration' />
          </div>
          <div className='m15 fw600'>
            <p> Efficient Collaboration</p>
            <p className='fw400'>Bring athletes, coaches, medical staff, and leaders together for streamlined collaboration and communication. </p>
          </div>
        </Para2>
      </div>
      {/* scroll 3 */}
      <h3 className='title2'>GETTING STARTED WITH HIGH PERFORMANCE WAS NEVER THIS EASY</h3>
      <div className='dfrow bgWhite'>
        <div className="dfcolcenter m30 mT" style={getNewStyle()}>
          <p className='para2'>Design a high-performance digital framework that is best suited for your organization needs.</p>
          <p className='para2'>Centrally manage data from different sources, look up evidence-backed research to optimize the training and engage with different stakeholders including athletes across the entire lifecycle.</p>
          <p className='para2'>Check out our ATIUM Sports platforms extensive capabilities from our
            <NavLink to="/features" style={{textDecoration: 'none', fontWeight: 600, color: '#616af6'}}> features </NavLink> page.
          </p>
        </div>
        <div className="dfcolcenter m30 mT" style={getNewStyle(true)}>
          <img src={allPlatfrom} alt="allPerformance" style={{width: 500, height: 350, margin: 'auto'}} />
          <img src={allPage} alt="allPage" />
          <span className='para fw600 ma'> All from a single platform.</span>
        </div>
      </div>
      {/* scroll 4 */}
      <h3 className='title2'>MONITOR & MANAGE</h3>
      <div className='dfrow bgPurple'>
        <div className="dfcolcenter m30 mT" style={getNewStyle()}>
          <p className='para2 ma'>Track and analyse data from performance tests, clinical assessments for individual athletes and teams. Gain instant insights and baseline your performance training by uploading existing historical data from any source. Easily schedule assessments and workouts for athletes while collaborating using shared calendars.</p>
        </div>
        <div className="dfcolcenter imgbackground1" style={getNewStyle(true)}>
          <img src={monitor} alt='monitor' />
        </div>
      </div>
      {/* scroll 5 */}
      <h3 className='title2'>INTELLIGENT & ACTIONABLE INSIGHTS</h3>
      <div className='dfrow bgWhite'>
        <div className="dfcolcenter imgbackground2 mL" style={getNewStyle()}>
          <img src={insight} alt='intelligent' />
        </div>
        <div className="dfcolcenter m30 mT" style={getNewStyle(true)}>
          <p className='para2 ma'>Benefit from holistic performance intelligence across the entire athlete training program. With global scoring and training load trend analysis, use key performance indicators represented in easy-to-understand dashboards to finally put your athlete's training data to good use and take decisions faster.</p>
        </div>
      </div>
      {/* scroll 6 */}
      <h3 className='title2'>OPTIMISE & IMPROVE</h3>
      <div className='dfrow bgPurple'>
        <div className="dfcolcenter m30 mT" style={getNewStyle()}>
          <p className='para2'>Benchmark your team and your athlete's training performance against recognized world records. Leverage evidence-backed, sports-science resources and protocols used by successful elite athlete, right from within the ATIUM platform to improve high-performance training.</p>
          <div className='dfrowC title2 mB mT'>
            <Button variant="outlined" className="fw600" startIcon={<FileDownloadIcon />} style={{fontSize: 'large'}}>
              <a href={AtiumReport} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: '#616af6'}}>Atium Report</a>
            </Button>
          </div>
        </div>
        <div className="dfcolcenter imgbackground1 p30" style={getNewStyle(true)}>
          <img src={insightBenchmark} alt='intelligent' />
        </div>
      </div>
      {/* scroll 8 */}
      <h3 className='title2'>Trusted by athletes and coaches from</h3>
      <div className='dfrowE bgWhite p30'>
        {Object.keys(trusted).map((key, i) => <div className='m20' key={i}>
          <img className='df ma' style={{width: 100, height: 100}} src={trusted[key]} alt={key}></img>
        </div>)}
      </div>
      <h3 className='title2'>Strategic Partners of ATIUM SPORTS</h3>
      <div className='dfrowC bgPurple p30'>
        {Object.keys(partners).map((key, i) => <div className='m20' key={i}>
          <img className='df ma' src={partners[key]} alt={key}></img>
        </div>)}
      </div>
      {/* Scroll 9 */}
      <div className='dfrowE bgWhite p30'>
        <Testimonial />
      </div>
      {/* Scroll 10 */}
      <div className='dfrowC bgPurple p30'>
        <Payments />
      </div>
    </div >
  )
}
