import React from 'react';
import FileViewer from 'react-file-viewer'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import COVID19 from '../pdf/COVID19.pdf'
import Covidoutbreak from '../pdf/CovidOutbreak.pdf'
import performanceInconfinement from '../pdf/performanceInconfinement.pdf'
import PerformanceTraining from '../pdf/PerformanceTraining.pdf'
import RoleOfSleep from '../pdf/RoleOfSleep.pdf'
import turntosocialmedia from '../pdf/turntosocialmedia.pdf'

const blogs = {
  'Role of sleep in an athlete’s performance and recovery': RoleOfSleep,
  'What keeps an athlete healthy during the COVID outbreak?': Covidoutbreak,
  'Social media and its relationship to an athlete’s performance in confinement': performanceInconfinement,
  'An athlete’s  turn to social media: to follow or not to follow?': turntosocialmedia,
  'Performance training during confinement: what does an athlete need?': PerformanceTraining,
  'COVID-19 second lockdown? How to be a well prepared athlete': COVID19,
}

export default function Blog() {

  return (<div style={{margin: 50}}>
    <h3 style={{textAlign: 'center', fontSize: 28, color: '#4d4203'}}>Our Blogs</h3>
    {Object.keys(blogs).map((blog, i) =>
      <div key={i} style={{margin: 5}}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={"panel"+i+"-content"} id={"panel"+i+"-header"} >
            <Typography style={{fontSize: 18, fontFamily: 'Poppins', fontWeight: 500}}>{blog}</Typography>
          </AccordionSummary>
          <AccordionDetails className="dfrowC">
            <div className="mT10 ma" style={{width: 670}}>
              {blogs[blog] !== '' ? <FileViewer fileType='pdf' filePath={blogs[blog]} /> : 'No File Found'}
            </div>
            {blogs[blog] !== '' && <Button variant="outlined" startIcon={<FileDownloadIcon />} style={{margin: '10px', left: '50%', marginLeft: '-50px', fontSize: 'large', fontWeight: 600, fontFamily: 'Poppins'}}>
              <a href={blogs[blog]} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>Download</a>
            </Button>}
          </AccordionDetails>
        </Accordion>
      </div>)}
    <h3 style={{textAlign: 'center', fontSize: 28, color: '#4d4203'}}>Our Videos</h3>
    <div className='dfrowC bgWhite'>
      <div className="dfcolcenter m30 mT">
        <iframe width="250" height="200" src="https://www.youtube.com/embed/A9xBhGZ4gHo?si=tavisV3YswN1NBfs" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" ></iframe>
      </div>
      <div className="dfcolcenter m30 mT">
        <iframe width="250" height="200" src="https://www.youtube.com/embed/qW9z3CwmrDk?si=b61HzL0hFul_oo4C" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className="dfcolcenter m30 mT">
        <iframe width="250" height="200" src="https://www.youtube.com/embed/bgYDWnLNRqw?si=NPAXkuNbsSzYRIO5" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" ></iframe>
      </div>
      <div className="dfcolcenter m30 mT">
        <iframe width="250" height="200" src="https://www.youtube.com/embed/iP6j40h2dP0?si=RDb0Sur9NjoLXcys" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className="dfcolcenter m30 mT">
        <iframe width="250" height="200" src="https://www.youtube.com/embed/rTEqWcW0xnY?si=DNefLJJjpg7Nra64" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
    <div className='dfrowC bgWhite'>
      <div className="dfcolcenter m30 mT">
        <iframe width="350" height="250" src="https://www.youtube.com/embed/videoseries?si=tz2YxMIDh6e_7xTM&amp;list=PLBNk3SZe1yaqunp2OfEpbeMNUrSu2ZvH1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <div className="dfcolcenter m30 mT">
        <iframe width="350" height="250" src="https://www.youtube.com/embed/videoseries?si=VGCzrZHS44obnMdh&amp;list=PLBNk3SZe1yaqp0WcPfincdEhWSVsl6DJL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
  </div>)
}
