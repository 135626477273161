import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {Button, CardActionArea, CardActions} from '@mui/material';
import saju from '../images/partners/saju.png'
import maxime from '../images/partners/maxime.png'

const testimonial = [
  {
    name: 'Dr. Saju Joseph',
    role: 'Ex - High Performance Director',
    client: 'Sports Authority of India',
    img: saju,
    quote: `“ATIUM's platform is easy for users to understand and comply with. It helps all stakeholders stay connected. The dashboards are simple to interpret and make decisions quickly. Availability of regional languages helps coaches and athletes to communicate easily.”`
  },
  {
    name: 'Maxime',
    role: 'Director of performance and coach',
    client: 'FFESSM France',
    img: maxime,
    quote: `“When selecting athletes for national team, with the wellness and internal load dashboard, we can track how they train and see which athlete has made how much progress. ATIUM has helped coaches better understand their athlete's response to the training load.”`
  }
]

export default function Testimonial() {
  return (
    <div className='dfrowC' >
      {testimonial.map((person, index) =>
        <Card sx={{maxWidth: 500, backgroundColor: '#eceefd', }} key={index} className='m30'>
          <CardActionArea style={{cursor: 'default !important'}}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" style={{fontStyle: 'italic'}} className='quote'>

                {person.quote}
              </Typography>

            </CardContent>
          </CardActionArea>
          <CardActions>
            <Avatar
              alt="Remy Sharp"
              src={person.img}
              sx={{width: 56, height: 56}}
            />
            <Button size="small" color="primary" disabled style={{display: 'flex', flexDirection: 'column', color: '#181818', alignItems: 'flex-start'}} >
              <span style={{fontWeight: '600', textAlign: 'start', textTransform: 'capitalize'}}>{person.name}</span>
              <span style={{fontWeight: '300', width: 250, textAlign: 'justify', textTransform: 'capitalize'}}> {person.role + ' |'}</span>
              <span style={{fontWeight: '300', textAlign: 'start', textTransform: 'capitalize'}}> {person.client}</span>
            </Button>
          </CardActions>
        </Card>
      )}
    </div>
  )
}
