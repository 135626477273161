import React from "react";

export default function Privacy() {

  return (
    <div className="p30">
      <b>Privacy Policy</b>
      <h6 className="mT"> Last updated: March 2023</h6>
      At SCERMLIND we are committed to protecting the privacy and security of your personal information. We developed this Privacy Policy to describe how we collect, use, share and store your personal information and applies to the information that we may obtain from you through your use of SCERMLIND’ software and services (including ATIUM platform, URUFIT wearable devices, our mobile apps, our websites, and our APIs), collectively called the “Services”. If you have any questions or need more information about these Services, please contact us at support@scermlind.com
      <br/>
      Within this Privacy Notice we will cover the following topics, to go to a specific section,
      <br/>
      <br/>
      <b>ABOUT US AND THIS NOTICE</b>
      <br/>
      Company Ltd Name: SCERMLIND Healthcare Innovations Pvt Ltd.
      <br/>
      Company Registered Number: ‎U93090TZ2019PTC031523
      <br/>
      Company Registered Office Address: SF No: 390, Frank Avenue, Singanallur,Coimbatore,641005
      <br/>
      SCERMLIND Healthcare Innovations Pvt Ltd. is a “data controller”. This means that we are responsible for deciding how we hold and use personal information about you. We are required under data protection legislation to notify you of the information contained in this privacy notice.
      <br/>
      It is important that you read this notice, together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal information about you, so that you are aware of how and why we are using such information.
      <br/>
      We reserve the right to update this privacy notice at any time. We may also notify you in other ways from time to time about the processing of your personal information.
      <br/>
      If you have any questions about this privacy notice or how we handle your personal information, please contact us atsupport@scermlind.com
      <br/>
      <br/>
      <b>THE INFORMATION WE COLLECT AND HOW WE COLLECT IT</b>
      <br/>
      Personal information means any information about an individual, from which that person can be identified. When you use our Services, we collect the following types of personalInformation:
      <br/>
      <u>Information You Provide To Us</u>
      <br/>
      Account Information: Some information is required to create an account to use ATIUM services, which includes your first and last name, email address, contact address and contact telephone numbers (if relevant). Therefore, we will ask you to provide some personal information, including your email address and name (you do not have to use your full name, but you may if you wish). This account will be password protected and you will use your email address to access your account. You can add other information to your account profile such as your location, gender, height, weight, date of birth, and the activities you participate in (e.g. football, running, jumping etc.). This information will be used for monitoring your performance and to provide insights and benchmarks about your activities (durations, distances,etc.).
      <br/>
      <u>ATIUM Data Lake</u>: The information shared with the Atium Web App and mobile Apps: first and last name, DOB, Avatar, all session data you the user has chosen to sync with the data lake.
      <br/>
      <u>Payment Information</u>: If you purchase SCERMLIND’s products, you provide your payment information, including your name, credit, debit card or PayPal account details and billing address. We do not store this information. We do store your shipping address to fulfil your order.
      <br/>
      <u>Information We Receive From Your Use Of Our Services</u>:
      <br/>
      Through the use of our ATIUM Applications we collect user uploaded data to estimate certain metrics regarding your sporting activity. These metrics include: Total Distance, High Metabolic Load Distance, High Speed Running, Metres per Minute, Step Balance, Accelerations, Decelerations, Dynamic Stress Load, Current Heart Rate, Maximum Heart Rate, Heart Rate Variability, Average Heart rate, Time in “Red Zone”, Current Speed, Max Speed, Sprint Distance, Number of Sprints and Average Calories expended. You will be able to view your current workout metrics on your App. When your App Account syncs with our servers over Wi Fi, the measurement metrics are backed up to our datalake. This backup enables you to track your progress and view your historic workouts.
      <br/>
      <u>Location Information</u>: Our Services include certain features that use location data, including GPS, accelerometers, Wi-Fi, and Bluetooth.
      <br/>
      <u>Usage Information</u>: We collect usage data when you use our Services. This includes information about your interaction with our Services, for example when you view content, install applications, create an account, pair your device or interact with your device via the application. We also collect cookie information and IP address information about the device or computer you use to access our Services. This will only be used for analysis purposes.
      <br/>
      <br/>
      <b>HOW WE USE THE INFORMATION</b>
      <br/>
      We will only use your personal information when the law allows us to. Most commonly, we will use your personal information in the following circumstances:
      <br/>
      Where we need to perform the contract we have entered into with you, for instance to fulfill your order for our products and provide our sports tracking and stats service.
      <br/>
      Where we need to comply with a legal obligation.
      <br/>
      Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.
      <br/>
      We may also use your personal information in the following situations, which are likely to be rare:
      <br/>
      Where we need to protect your interests (or someone else’s interests).
      <br/>
      Where it is needed in the public interest. We need your personal information primarily to allow us to perform our contract with you to provide our goods and services and to enable us to comply with legal obligations. In some cases we may use your personal information to pursue legitimate interests of our own or those of third parties, provided your interests and fundamental rights do not override those interests. The situations in which we will process your personal information are listed below.
      <br/>
      * Administering the contract we have entered into with you.
      <br/>
      * To provide, improve and develop our Services.
      <br/>
      * To communicate with you.
      <br/>
      * To promote safety and security
      <br/>
      * To prevent fraud.
      <br/>
      Some of the above grounds for processing will overlap and there may be several grounds which justify our use of your personal information. If you fail to provide personal information or If you fail to provide certain information when requested, we may not be able to perform the contract we have entered into with you (such as fulfilling your order or providing our service).
      <br/>
      <u>Change of purpose</u>
      <br/>
      We will only use your personal information for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal information for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
      <br/>
      Please note that we may process your personal information without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
      <br/>
      <br/>
      <b>HOW INFORMATION IS SHARED</b>
      <br/>
      We will share your personal information with third parties if and where required by law, where it is necessary to administer the working relationship with you or where we have another legitimate interest in doing so. The following third-party service providers process personal information about you for the following purposes: AWS (Amazon Web Services).
      <br/>
      Which third-party service providers process my personal information?
      <br/>
      "Third parties" include third-party service providers (including contractors and designated agents) and other entities within our group.
      <br/>
      How secure is my information with third-party service providers and other entities in our group?
      <br/>
      All our third-party service providers and other entities in the group are required to take appropriate security measures to protect your personal information in line with our policies. We do not allow our third-party service providers to use your personal data for their own purposes. We only permit them to process your personal data for specified purposes and in accordance with our instructions.
      <br/>
      When might you share my personal information with other entities in the group?
      <br/>
      We will share your personal information with other entities in our group as part of our regular reporting activities on company performance, in the context of a business reorganisation or group restructuring exercise, for system maintenance support and hosting of data.
      <br/>
      What about other third parties?
      <br/>
      We may share your personal information with other third parties, for example in the context of the possible sale or restructuring of the business. We may also need to share your personal information with a regulator or to otherwise comply with the law.
      <br/>
      <br/>
      <b>STORAGE AND SECURITY</b>
      <br/>
      We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal information on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.
      <br/>
      <br/>
      <b>RETENTION OF YOUR PERSONAL DATA</b>
      <br/>
      We will only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements. In some circumstances we may anonymise your personal information so that it can no longer be associated with you, in which case we may use such information without further notice to you. If you fail to access your SCERMLIND’ account for a period of 12 months we will securely destroy all your personal information we hold in accordance with applicable laws and regulations, at which point you will no longer be able to access your sports measurements and records.
      <br/>
      <br/>
      <b>HOW TO ACCESS OR DELETE YOUR DATA</b>
      <br/>
      We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
      <br/>
      If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at support@scermlind.com.
      <br/>
      In certain circumstances, you have the following data protection rights:
      <br/>
      Request access to your personal information (commonly known as a “data subject access request”). This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.
      <br/>
      Request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.
      <br/>
      Request erasure of your personal information. This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right toobject to processing.
      <br/>
      Object to processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground. You also have the right to object where we are processing your personal information for direct marketing purposes.
      <br/>
      Request the restriction of processing of your personal information. This enables you to ask us to suspend the processing of personal information about you, for example if you want us to establish its accuracy or the reason for processing it.
      <br/>
      Request the transfer of your personal information to another party.
      <br/>
      If you want to review, verify, correct or request erasure of your personal information,object to the processing of your personal data, or request that we transfer a copy of your personal information to another party, please contact our customer service team by emailing to support@scermlind.com. It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes during your working relationship with us. In the circumstances where you may have provided your consent to the collection, processing and transfer of your personal information for a specific purpose, for instance to provide you with marketing communication, you have the right to withdraw your consent for that specific processing at any time. To withdraw your consent, please contact our customer service team by emailing support@scermlind.com to withdraw your consent on our website. Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law.
      <br/>
      <br/>
      <u>Our Policy on Do Not Track Signals:</u>
      <br/>
      We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.
      <br/>
      You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
      <br/>
      <br/>
      <u>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</u>
      <br/>
      If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR.
      <br/>
      In certain circumstances, you have the following data protection rights:
      <br/>
      The right to access, update or to delete the information we have on you;
      <br/>
      The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;
      <br/>
      The right to object. You have the right to object to our processing of your Personal Data;
      <br/>
      The right of restriction. You have the right to request that we restrict the processing of your personal information;
      <br/>
      The right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;
      <br/>
      The right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;
      <br/>
      Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not be able to provide Service without some necessary data.
      <br/>
      You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
      <br/>
      <br/>
      <u>Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</u>
      <br/>
      According to CalOPPA we agree to the following:
      <br/>
      * users can visit our site anonymously;
      <br/>
      * our Privacy Policy link includes the word "Privacy", and can easily be found on the home page of our website;
      <br/>
      * users will be notified of any privacy policy changes on our Privacy Policy Page;
      <br/>
      * users are able to change their personal information by emailing us at support@scermlind.com.
      <br/>
      <br/>
      <u>Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</u>
      <br/>
      If you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:
      <br/>
      1. What personal information we have about you. If you make this request, we will return to you:
      <br/>
      <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>1.1. The categories of personal information we have collected about you.
      <br/>
      <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>1.2. The categories of sources from which we collect your personal information.
      <br/>
      <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>1.3. The business or commercial purpose for collecting or selling your personal information.
      <br/>
      <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>1.4. The categories of third parties with whom we share personal information.
      <br/>
      <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>1.5. The specific pieces of personal information we have collected about you.
      <br/>
      <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>1.6. A list of categories of personal information that we have sold, along with the category of any other company we sold it to.
      <br/><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>If we have not sold your personal information, we will inform you of that fact.
      <br/>
      <b>&nbsp;&nbsp;&nbsp;&nbsp;</b>1.7. A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.
      <br/><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period.
      <br/><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.
      <br/>
      2. To delete your personal information. If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate.
      <br/>
      3. To stop selling your personal information. We don’t sell or rent your personal information to any third parties for any purpose. We do not sell your personal information for monetary consideration. However, under some circumstances, a transfer of personal information to a third party, or within our family of companies, without monetary consideration may be considered a “sale” under California law. You are the only owner of your Personal Data and can request disclosure or deletion at any time.
      <br/>
      If you submit a request to stop selling your personal information, we will stop making such transfers.
      <br/>
      To exercise your California data protection rights described above, please send your request(s) by email: support@scermlind.com
      <br/>
      Your data protection rights, described above, are covered by the CCPA, short for the California Consumer Privacy Act. To find out more, visit the official California Legislative Information website. The CCPA took effect on 01/01/2020.
    </div>
  )
}
