import {Route, Routes} from "react-router-dom";
import Blog from "./Component/Blog";
import Contact from "./Component/Contact";
import Features from "./Component/Features";
import Home from "./Component/Home";
import Navigationbar from "./Component/Navigationbar";
import Footer from "./Component/Footer";
// policies
import TandC from "./Component/policies/tanc"
import Refund from "./Component/policies/refund"
import Privacy from "./Component/policies/privacy"

function LandingPage() {
  return (
    <div className="App">
      <Navigationbar/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tandc" element={<TandC />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/privacypolicy" element={<Privacy />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default LandingPage;
