import React from "react";
import atiumLogo from '../images/atiumLogo.png'
import '../Style/style.css'
// Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {NavLink} from "react-router-dom";

const policies = {
  'Privacy Policy': '/privacypolicy',
  'Terms & Conditions': '/tandc',
  'Cancellation & Refund policy': '/refund'
}

const company = {
  'SCERMLIND Healthcare Innovations Pvt. Ltd.': 'https://www.scermlind.com/',
  'ASSESSPro': 'https://assesspro-web.atium.in/',
  'URUFIT': 'https://www.urufit.com/'
}

export default function Footer() {
  return (
    <div className='footer dfrowE p30'>
      <div>
        <div className="dfrow">
          <img src={atiumLogo} alt="atium" style={{width: 150}} />
          <p style={{margin: 'auto', color: '#F2D74A', padding: 15}} >Performance Intelligence Platform</p>
        </div>
        <p>© 2023 SCERMLIND Healthcare. All Rights Reserved.</p>
      </div>
      <div>
        <h5 style={{color: '#F2D74A', fontSize: 'larger', margin: 0}}>ATIUM Sports</h5>
        <ul style={{display: 'flex', justifyContent: 'flex-start'}}>
          <li style={{listStyle: 'none'}}>
            <NavLink to={'/contact'} style={{textDecoration: 'none', color: 'white'}}>
              Contact Us
            </NavLink>
          </li>
        </ul>
        {Object.keys(policies).map((policy, i) =>
          <ul key={i} style={{display: 'flex', justifyContent: 'flex-start'}}>
            <li style={{listStyle: 'none'}}>
              <NavLink to={policies[policy]} style={{textDecoration: 'none', color: 'white'}}>
                {policy}
              </NavLink>
            </li>
          </ul>
        )}
      </div>
      <div>
        <h5 style={{color: '#F2D74A', fontSize: 'larger', margin: 0}}>COMPANY</h5>
        {Object.keys(company).map((key, i) =>
          <ul key={i} style={{display: 'flex', justifyContent: 'flex-start'}}>
            <li style={{listStyle: 'none'}}>
              <NavLink to={company[key]} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>
                {key}
              </NavLink>
            </li>
          </ul>
        )}
      </div>
      <div className="dfcolumn">
        <h5 style={{color: '#F2D74A', fontSize: 'larger', margin: 0}}> Follow US</h5>
        <div className="dfrowC" style={{marginTop: 15, gap: 10}}>
          <a href="https://www.instagram.com/atiumsports/" target="_blank" rel="noreferrer">
            <InstagramIcon style={{fontSize: 40, color: '#eceefd'}} />
          </a>
          <a href="https://www.facebook.com/atiumsports" target="_blank" rel="noreferrer">
            <FacebookIcon style={{fontSize: 40, color: '#eceefd'}} />
          </a>
          <a href="https://www.linkedin.com/company/atiumsports/" target="_blank" rel="noreferrer">
            <LinkedInIcon style={{fontSize: 40, color: '#eceefd'}} />
          </a>
          <a href="https://www.youtube.com/@AtiumSports" target="_blank" rel="noreferrer">
            <YouTubeIcon style={{fontSize: 40, color: '#eceefd'}} />
          </a>
        </div>
        <a className="libutton m20" href="https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7222557533553451008" target="_blank" rel="noreferrer">
          Subscribe on LinkedIn
        </a>
      </div>
    </div>
  )
}
