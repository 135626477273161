import React from 'react';
import {Button, TextField} from '@mui/material';

function getNewStyle(i) {
  return {
    width: window.innerWidth > 800 ? "calc(50% - 62px)" : "calc(100% - 30px)",
    marginLeft: i && window.innerWidth > 800 ? 0 : 30,
  }
}

export default function Contact() {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [message, setMessage] = React.useState("")

  const handleSubmit = (n, e, p, m) => {
    if (e) {
      fetch('https://sales-dev.atium.in/v1/contact', {
        method: 'POST',
        headers: {
          'x-api-key': 'PmdomFH9m61M336KINGs61ndtcUv6wq44Jaa1048'
        },
        body: JSON.stringify({name: n, email: e, phone: p, message: m})
      }).then(res => {
        return res.status === 201 ? res.json() : null
      }).then(data => {
        setName("")
        setEmail("")
        setPhone("")
        setMessage("")
      }).catch(err => {
        console.log(err)
      })
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="dfrow bgPurple p30">
        <div className='dfcolumn' style={getNewStyle()}>
          <h3 style={{fontSize: 40, fontWeight: 600, color: '#4F4F4F', textAlign: 'center'}}>CURIOUS TO LEARN MORE ?</h3>
          <p style={{fontSize: 25, fontWeight: 400, color: '#4F4F4F', margin: 0, textAlign: 'center'}}>
            We'd love to talk to you and understand your needs. Fill in your details in the form and we will get in touch with you.
          </p>
        </div>
        <div className='dfcolcenter' style={getNewStyle(true)}>
          <TextField label='Name'
            onChange={e => {setName(e.target.value);}}
            style={{margin: '5px 25px', borderRadius: 10, background: 'white'}} />
          <TextField label='Email'
            onChange={e => {setEmail(e.target.value);}}
            style={{margin: '5px 25px', borderRadius: 10, background: 'white'}} />
          <TextField label='Phone'
            onChange={e => {setPhone(e.target.value);}}
            style={{margin: '5px 25px', borderRadius: 10, background: 'white'}} />
          <TextField label='Type Your Message here...'
            onChange={e => {setMessage(e.target.value);}}
            style={{margin: '5px 25px', borderRadius: 10, background: 'white'}} />
          <div className='dfrowC title2 mB'>
            <Button variant='contained' onClick={e => {handleSubmit(name, email, phone, message)}}
              style={{background: '#616af6', color: 'white'}}> Request Call Back</Button>
          </div>
        </div>
      </div >
      <div className="dfrow" style={{background: 'black', minHeight: 300}} >
        <div className='dfcolcenter imgbackground2 p30 mL' style={getNewStyle()}>
          <h3 style={{textAlign: 'center', color: 'white', fontSize: 40, margin: 5}}>REACH OUT DIRECTLY !</h3>
          <p style={{textAlign: 'center', color: '#F2D74A', fontSize: 25, fontWeight: 400, margin: 0, padding: 0}}><a href="mailto:murugesh@atiumsports.com" style={{textDecoration: 'none', color: 'white', fontSize: 15}}>murugesh@atiumsports.com</a></p>
        </div>
        <div className="dfcolcenter p30" style={getNewStyle(true)}>
          <p style={{textAlign: 'center', color: '#F2D74A', fontSize: 25, fontWeight: 400, margin: 0, padding: 0}}>Our Offices</p>
          <p style={{textAlign: 'center', color: 'white', margin: '0px 5px', padding: 0}}>ATIUM SPORTS</p>
          <p style={{textAlign: 'center', color: 'white', margin: 0, padding: 0}}> SCERMLIND Healthcare Innovations Pvt Ltd,</p>
          <p style={{textAlign: 'center', color: 'white', margin: 0, padding: 0}}>  CA Site, No 1, HAL 3rd Stage EXTN</p>
          <p style={{textAlign: 'center', color: 'white', margin: 0, padding: 0}}> Kodihalli, Bengaluru, Karnataka 560008 </p>
        </div>
      </div>
    </div >)
}
