import React, {useState} from "react";
import atiumLogo from '../images/atiumLogo.png'
import "../Style/navbar.css";
import {Link, NavLink} from "react-router-dom";

export default function Navigationbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav>
      <Link to="/" className="mL10 dfrowS" style={{textDecoration: 'none'}}>
        <img src={atiumLogo} className="homeLogo" alt="homeLogo" />
        <p className="fw600 ma mL20" style={{fontSize: 30, color: '#fafafd'}}>ATIUM SPORTS</p>
      </Link>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/features">Features</NavLink>
        </li>
        <li>
          <NavLink to="/blog">Blogs & Videos</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
      </ul>
    </nav>
  );
};
