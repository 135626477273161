import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {NavLink} from 'react-router-dom';

export default function Payments() {
  return (
    <div className='dfrowC'>
      <Card sx={{minWidth: 275, height: 550, width: 470, borderRadius: '35px 0px 0px 35px'}} variant="outlined" className='dfcolaround'>
        <CardContent className='ma'>
          <Typography sx={{fontSize: 26, fontWeight: 600, color: 'black', fontFamily: 'poppins-semibold, poppins, sans-serif'}} color="text.secondary" gutterBottom>
            6 Months
          </Typography>
          <Typography sx={{fontSize: 18, fontWeight: 600, color: 'black', fontFamily: 'poppins-semibold, poppins, sans-serif'}} variant="h5" component="div">
            <span style={{fontSize: 60}}>26$/ <span style={{fontSize: 18}}>6 months</span></span>
          </Typography>
          <Typography sx={{fontSize: 20, fontWeight: 600, color: 'blue', fontFamily: 'poppins-semibold, poppins, sans-serif', overflowWrap: 'break-word'}} color="text.secondary">
            - 6 months + 1 Month Additional
          </Typography>
          <Typography sx={{fontSize: 20, fontWeight: 600, color: 'blue', fontFamily: 'poppins-semibold, poppins, sans-serif', overflowWrap: 'break-word'}} color="text.secondary">
            - $3.71 per Month
          </Typography>
          <Typography sx={{fontSize: 20, fontWeight: 600, color: 'blue', fontFamily: 'poppins-semibold, poppins, sans-serif', overflowWrap: 'break-word'}} color="text.secondary">
            - Plan for per user
          </Typography>
        </CardContent>
        <CardActions style={{justifyContent: 'center'}}>
          <Button size="medium" variant="outlined" style={{background: 'black', borderRadius: 45}}>
            <NavLink to="/contact" className='learn' style={{color: 'white'}}>
              Learn More
            </NavLink>
          </Button>
        </CardActions>
      </Card>
      <Card sx={{minWidth: 275, height: 550, width: 470, background: '#616af6', borderRadius: '0px 35px 35px 0px'}} variant="outlined" className='dfcolaround'>
        <CardContent className='ma'>
          <Typography sx={{fontSize: 26, fontWeight: 600, color: 'white', fontFamily: 'poppins-semibold, poppins, sans-serif'}} color="text.secondary" gutterBottom>
            Yearly
          </Typography>
          <Typography sx={{fontSize: 18, fontWeight: 600, color: 'white', fontFamily: 'poppins-semibold, poppins, sans-serif'}} variant="h5" component="div">
            <span style={{fontSize: 60}}>44$/ <span style={{fontSize: 18}}>year</span></span>
          </Typography>
          <Typography sx={{fontSize: 20, fontWeight: 600, color: 'white', fontFamily: 'poppins-semibold, poppins, sans-serif', overflowWrap: 'break-word'}} color="text.secondary">
            - 12 month + 2 Month Additional
          </Typography>
          <Typography sx={{fontSize: 20, fontWeight: 600, color: 'white', fontFamily: 'poppins-semibold, poppins, sans-serif', overflowWrap: 'break-word'}} color="text.secondary">
            - $3.14 per Month
          </Typography>
          <Typography sx={{fontSize: 20, fontWeight: 600, color: 'white', fontFamily: 'poppins-semibold, poppins, sans-serif', overflowWrap: 'break-word'}} color="text.secondary">
            - Plan for per user
          </Typography>
        </CardContent>
        <CardActions style={{justifyContent: 'center'}}>
          <Button size="medium" variant="outlined" style={{background: 'white', borderRadius: 45}}>
            <NavLink to="/contact" className='learn' style={{color: 'black'}}>
              Learn More
            </NavLink>
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}
